<template>
    <div>
        <b-row>
            <!-- imageformodel -->
            <b-col cols="12" v-show="displayUploadImageTagDiv">
                <b-form-input type="text" name="imageformodeltemp" v-model="previewUrl" :hidden="true"/>
                <b-form-group label="Image placement" label-for="imageformodel">
                    <b-form-file ref="file-input" v-model="selectedValues.imageformodel" class="mb-2" @change="uploadImage"/>
                        <b-media no-body v-show="checkImageExistsAndChange()" class="mb-1">
                            <b-media-body>
                                <h6 class="transaction-title" >
                                    Selected file:
                                </h6>
                                <small class="text-break">{{ selectedValues.imageformodel ? (selectedValues.imageformodel.name ? selectedValues.imageformodel.name : selectedValues.imageformodel) : '' }}</small>
                            </b-media-body>
                            <b-media-aside>
                                <b-button variant="danger" class="btn-icon ml-1" @click="clearFiles()">
                                    <feather-icon icon="Trash2Icon" />
                                </b-button>
                            </b-media-aside>
                        </b-media>
                </b-form-group>
            </b-col>

            <!-- modelimageplacement -->
            <b-col cols="12" v-show="displayUploadImageTagDiv">
                <b-form-group label="Image placement" label-for="modelimageplacement">
                    <Select2 name="modelimageplacement" :options="modelImagePlacementOptions" v-model="selectedValues.modelimageplacement" />
                </b-form-group>
            </b-col>

            <b-col cols="12" v-show="!displayUploadImageTagDiv">
                <b-card-text>Site Bar, Slider, Splitscreen or Slide bar images are not supported. You need to use Modal, Page Takeover or Side Modal to upload images</b-card-text>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import { BRow, BCol, BFormFile, BButton, BMediaBody, BMedia, BMediaAside, BFormGroup, BCardText, BFormInput } from 'bootstrap-vue'
    import captureOptions from "@/libs/capture-Options"
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

    export default {
        components: {
            BRow, BCol, BFormFile, BButton, BMediaBody, BMedia, BMediaAside, BFormGroup, BCardText, BFormInput
        },
        props: {
            prop_siteSettingArray: {
                type: Object,
                default: () => { },
            }
        },
        setup() {
            const { modelImagePlacementOptions } = captureOptions();
            return {
                modelImagePlacementOptions
            }
        },
        data() {
            return {
                imageUploaded: false,
                file: '',
                displayUploadImageTagDiv: false,
            }
        },
        methods: {
            clearFiles() {
                this.$refs['file-input'].reset();
                this.selectedValues.imageformodel = '';
                this.checkImageExistsAndChange();
                this.prop_siteSettingArray.imageformodeltemp = '';
            },
            checkImageExistsAndChange(){
                if(this.selectedValues.imageformodel) {
                    return true;
                }else{
                    return false;
                }
            },
            uploadImage(e){
                const uploadedFile = e.target.files[0];
                const maxSize = 10 * 1024 * 1024;
                if(uploadedFile.size > maxSize){
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Notification',
                            icon: 'BellIcon',
                            variant: 'danger',
                            text: 'File size exceeds maximum limit, file size is allowed up to 10 MB',
                        },
                    });
                    this.prop_siteSettingArray.imageformodeltemp = '';
                    this.previewUrl = null;
                    this.$refs['file-input'].reset();
                }else if(!['image/png','image/jpeg','image/webp','image/svg'].includes(uploadedFile.type)){

                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Notification',
                            icon: 'BellIcon',
                            variant: 'danger',
                            text: 'Uploaded file should be an image.',
                        },
                    });
                    this.prop_siteSettingArray.imageformodeltemp = '';
                    this.previewUrl = null;
                    this.$refs['file-input'].reset();

                }else{
                    this.previewUrl = URL.createObjectURL(uploadedFile);
                    this.prop_siteSettingArray.imageformodeltemp = URL.createObjectURL(uploadedFile);
                }
            }
        },
        computed: {
            /* GETS THE PROP VALUE INTO ANOTHER FUNCTION USING COMPUTED */
            selectedValues() {
                if (this.prop_siteSettingArray) {
                    if (_.includes(['2', '4', '6'], this.prop_siteSettingArray.styleType)) { /* 2: MODAL, 4: Page Takeover, 6: Side Modal */
                        this.displayUploadImageTagDiv = true;
                    } else {
                        this.displayUploadImageTagDiv = false;
                    }
                    return this.prop_siteSettingArray;
                } else {
                    return {};
                }
            },
        },
        watch: {
            prop_siteSettingArray: {
                handler(settingNewVal) {
                    if (settingNewVal.imageformodel != '') {
                        this.checkImageExistsAndChange()
                    }
                    /* IF SUBGOAL IS 2: MODAL, 4: Page Takeover, 6: Side Modal SELECTED THEN STYLE ONLY SHOW IMAGE UPLOAD DIV DISPLAY */
                    if (_.includes(['2', '4', '6'], settingNewVal.styleType)) {
                        this.displayUploadImageTagDiv = true;
                    } else {
                        this.displayUploadImageTagDiv = false;
                    }
                },
                deep: true,
            },
        },
        created() {
            /** SET DEFAULT IMAGE-PLACEMENT WHEN ADD NEW A CAPTURE */
            if (_.isEmpty(this.selectedValues.modelimageplacement)) {
                this.selectedValues.modelimageplacement = 'top';
            }
        }
    }
</script>
<style type="scss">
    input[type='color'] {
        width: 5rem;
    }
</style>